<template>
    <div>
        <div class="pb-6 diagonal pb-8 pt-0 pt-md-5" >
            <div class="row">
            </div>
        </div>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow">
                        <div class="card-header border-0">
                            <div class="row align-items-center">
                                <div class="col"><h3 class="mb-0">Položky menu</h3></div>
                                <div class="col text-right">
                                    <router-link :to="'/menu-item/' + id + '/add'"  class="nav-link"
                                                 :href="'/menu-item/' + id + '/add'">
                                        <template>
                                            <a-button type="primary" >Nová menu položka</a-button>
                                        </template>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <div class="table-flush ant-table-wrapper">
                                <div class="ant-table-body">
                                    <table class="ant-table " style="width:100%">
                                        <thead class="ant-table-thead">
                                        <tr>
                                            <th scope="col" colspan="2" style="text-align:left" class="ant-table-column-has-actions ant-table-column-has-sorters">Menu položky</th>
                                        </tr>
                                        </thead>

                                        <div class="control_wrapper">
                                            <ejs-treeview id='template' @dataSourceChanged="updateFields" v-model="fields" :nodeTemplate="treeTemplate"   :allowEditing='true' :fields="fields" :allowDragAndDrop='true'>

                                            </ejs-treeview>
                                        </div>


                                    </table></div></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {dataService} from "../../_services";
    import Vue from "vue";
    import { TreeViewPlugin } from "@syncfusion/ej2-vue-navigations";
    import treeTemplateVue from "./tree-template.vue";
    Vue.use(TreeViewPlugin);
    const columns = [
        {
            title: 'Názov menu',
            dataIndex: 'title',
            sorter: true,
            width: '20%',
        },
        {
            title: 'URL',
            dataIndex: 'url',
        },
        {
            align: 'right',
            scopedSlots: {customRender: 'name'},
        }
    ];

    export default {
        name: "menuitems",
        props: ['id'],
        data () {
            return {
                fields: {},
                data: [],
                sendata:{},
                colorsto: JSON.parse(localStorage.colors),
                dragging: false,
                loading: false,
                treeTemplate: function() {
                    return {
                        template: treeTemplateVue
                    };
                },
                columns,
            };
        },
        mounted() {
            this.fetch();
        },
        methods: {
            confirm(e) {
                dataService.axiosDelete(this.$options.name, e).then(results => {
                    if (results) {
                        this.data.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },
            updateFields(e)
            {

                this.sendata = e;
                dataService.axiosPost("reorder/menuitems", this.sendata).then(results => {
                    if(results.data)
                    {
                        this.$message.success('Položky boli zoradené');
                    }

                })
            },
            cancel() {
            },
            updateOrder()
            {
                dataService.axiosPost("reorder/menuitems", this.data).then(results => {
                    if(results.data)
                    {
                        this.$message.success('Položky boli zoradené');
                    }

                })
            },
            fetch() {
                this.loading = true;
                dataService.axiosFetch(this.$options.name,this.id).then(results => {
                    this.data = results;
                    this.fields = { dataSource: results, id: 'id', text: 'url', url: 'name', child: 'child', selected: 'isSelected' };
                    this.loading = false;
                });
            }

        },
    };
</script>

<style>

    @import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
    @import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";

    ul.ant-pagination {
        margin-top: 0;
        margin-bottom: 1em;
    }
    .handle i
    {
        color: #888888;
    }
    .ant-table-pagination.ant-pagination {
        margin-bottom: 40px;
        margin-right: 20px;
    }

    .ant-pagination5-item {
        border: 0;
    }

    .ant-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
    }

    .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a, .ant-pagination5-jump-prev a, .ant-pagination5-jump-next a {
        -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        border: 0;
    }

    .ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 3px;
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }


    .ant-pagination5-item.ant-pagination5-item-active a {
        z-index: 1;
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }

    .ant-pagination5-item-link.ant-pagination5-disabled a {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }
    #template .treeUrl {
        float: right;
        opacity:.5
    }
    #template .nodetext {
        float: left;
    }
    .control_wrapper
    {width:100%;
        display:inline-block;
        overflow:visible;}
</style>
